<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="jump"
  >
    <div class="tag" v-if="dataSource.reexamId">
      <span class="yellow">
        {{ $t("make_up_exam")
        }}<!-- 补考 -->
      </span>
    </div>
    <div class="cover-wrap">
      <div
        class="cover-content cover-blue"
        v-if="currentTime < dataSource.startTime"
      >
        <div class="img">
          <img src="@/assets/image/mine/exam_1.png" alt="exam" />
        </div>
        <div class="text">
          <a-popover trigger="hover" v-if="dataSource.reexamId">
            <template #content>
              <div class="popover-content">
                {{ dataSource.taskName }}
              </div>
            </template>
            {{ timeRmaining(dataSource.startTime)
            }}{{ $t("LB_Error_ExamHasTime")
            }}<!-- 后开始考试 -->
          </a-popover>
          <template v-else>
            {{ timeRmaining(dataSource.startTime)
            }}{{ $t("LB_Error_ExamHasTime")
            }}<!-- 后开始考试 -->
          </template>
        </div>
      </div>
      <div
        class="cover-content cover-gray"
        v-else-if="currentTime > dataSource.endTime"
      >
        <div class="img">
          <img src="@/assets/image/mine/exam_3.png" alt="exam" />
        </div>
        <div class="text">
          <a-popover trigger="hover" v-if="dataSource.reexamId">
            <template #content>
              <div class="popover-content">
                {{ dataSource.taskName }}
              </div>
            </template>
            {{ $t("LB_Error_ExamHasEnd")
            }}<!-- 考試已結束 -->
          </a-popover>
          <template v-else>
            {{ $t("LB_Error_ExamHasEnd")
            }}<!-- 考試已結束 -->
          </template>
        </div>
      </div>
      <div class="cover-content cover-orange" v-else>
        <div class="img">
          <img src="@/assets/image/mine/exam_2.png" alt="exam" />
        </div>
        <div class="text">
          <a-popover trigger="hover" v-if="dataSource.reexamId">
            <template #content>
              <div class="popover-content">
                {{ dataSource.taskName }}
              </div>
            </template>
            {{ $t("LB_Error_ExamHasIng") }}...<!-- 考试进行中 -->
          </a-popover>
          <template v-else>
            {{ $t("LB_Error_ExamHasIng") }}...<!-- 考试进行中 -->
          </template>
        </div>
      </div>
    </div>
    <div class="detail">
      <h5>
        <template v-if="listType == 'list'">
          <span class="status bg-green" v-if="dataSource.status === 8">
            {{ $t("Pub_Passed")
            }}<!-- 已通过 -->
          </span>
          <span class="status bg-green" v-else-if="dataSource.status === 9">
            {{ dataSource.reexamId ? $t("Pub_Passed") : $t("exam.make_up_pass")
            }}<!-- '已通过' : '补考通过' -->
          </span>
          <span class="status bg-red" v-else-if="dataSource.status === 2">
            {{ $t("XB_Unthread")
            }}<!-- 不通过 -->
          </span>
          <span class="status bg-gray" v-else-if="dataSource.myTimes === 0">
            {{ $t("CM_NoTakePartIn")
            }}<!-- 未参与 -->
          </span>
          <span class="status bg-gray" v-else>
            {{ $t("XB_Exam_PendingApproval")
            }}<!-- 待批阅 -->
          </span>
        </template>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{
                dataSource.reexamId
                  ? dataSource.reexamName
                  : dataSource.taskName
              }}
            </div>
          </template>
          {{
            dataSource.reexamId ? dataSource.reexamName : dataSource.taskName
          }}
        </a-popover>
      </h5>
      <div class="row" v-if="dataSource.reexamId && listType == 'list'">
        {{ $t("exam.test_name") }}：{{ dataSource.taskName }}
        <!-- 主考名称 -->
      </div>
      <div class="row">
        {{ $t("LB_Exam_EnterCounts") }}：<span class="blue">{{
          dataSource.myTimes
        }}</span
        >/{{
          dataSource.examTimes == -2
            ? $t("exam.unlimited") // 不限次数
            : dataSource.examTimes == -1
            ? 1
            : dataSource.examTimes
        }}
        <!-- 参考次数 -->
      </div>
      <div class="row" v-if="dataSource.taskType != 8">
        <span v-show="listType == 'list'">{{ $t("Pub_Lab_ExamTime") }}：</span
        ><img
          class="icon"
          v-show="listType == 'card'"
          src="@/assets/image/mine/time.png"
          alt="time"
        /><span class="fs12"
          >{{ dateFormat(dataSource.startTime) }} ～
          {{ dateFormat(dataSource.endTime) }}</span
        >
        <!-- 考试时间 -->
      </div>
      <div class="row space-between" v-show="listType == 'card'">
        <div class="left">
          <span v-if="dataSource.status == 1">
            {{ $t("XB_Exam_PendingApproval")
            }}<!-- 待批阅 -->
          </span>
          <span
            class="green"
            v-else-if="dataSource.status === 8 || dataSource.status === 9"
          >
            {{ dataSource.score }}{{ $t("CM_Points") }}
          </span>
          <span class="red" v-else-if="dataSource.status === 2">
            {{ dataSource.score }}{{ $t("CM_Points") }}
          </span>
          <span v-else-if="dataSource.myTimes == 0">
            {{ $t("exam.not_score")
            }}<!-- 未得分 -->
          </span>
        </div>
        <div class="right">
          <span v-if="dataSource.status == 1">
            {{ $t("XB_Exam_PendingApproval")
            }}<!-- 待批阅 -->
          </span>
          <span
            class="green"
            v-else-if="dataSource.status === 8 || dataSource.status === 9"
          >
            {{ $t("Pub_Passed")
            }}<!-- 已通过 -->
          </span>
          <span class="red" v-else-if="dataSource.status === 2">
            {{ $t("XB_Unthread")
            }}<!-- 不通过 -->
          </span>
          <span v-else-if="dataSource.myTimes == 0">
            {{ $t("CM_NoTakePartIn")
            }}<!-- 未参与 -->
          </span>
        </div>
      </div>
      <div class="row" v-show="listType == 'list'">
        <span class="fs16 fw600" v-if="dataSource.status == 1">
          <!-- 待批阅 -->
          {{ $t("exam.exam_score") }}：<i class="fs16 fw600 green">--</i>
        </span>
        <span v-else-if="dataSource.status === 8 || dataSource.status === 9">
          {{ $t("exam.exam_score") }}：<i class="fs16 fw600 green"
            >{{ dataSource.score }}{{ $t("CM_Points") }}</i
          >
          <!-- 考试得分 -->
        </span>
        <span v-else-if="dataSource.status === 2">
          {{ $t("exam.exam_score") }}：<i class="fs16 fw600 red"
            >{{ dataSource.score }}{{ $t("CM_Points") }}</i
          >
          <!-- 考试得分 -->
        </span>
        <span class="fs16 fw600" v-else-if="dataSource.myTimes == 0">
          {{ $t("exam.not_score")
          }}<!-- 未得分 -->
        </span>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { timeRmaining, dateFormat } from "@/utils/tools";
export default {
  name: "ExamCard",
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const router = useRouter();

    const jump = () => {
      if (props.newWindow) {
        let url = `/exam/detail?ddtab=true&id=${props.dataSource.taskId}&did=${props.dataSource.detailId}`;
        if (props.dataSource.reexamId) {
          url = url + `&reexamId=${props.dataSource.reexamId}&taskType=23`;
        }
        window.open(url);
      } else {
        let query = {
          id: props.dataSource.taskId,
          did: props.dataSource.detailId,
        };
        if (props.dataSource.reexamId) {
          query.reexamId = props.dataSource.reexamId;
          query.taskType = 23;
        }
        router.push({
          path: "/exam/detail",
          query: query,
        });
      }
    };

    return {
      timeRmaining,
      dateFormat,
      currentTime: Date.parse(new Date()) / 1000,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  .tag {
    background-color: #efff33 !important;
    .yellow {
      color: #e8673e;
    }
  }
  &.card {
    .cover-wrap {
      width: 289px;
      height: 163px;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
    }
    .row.space-between {
      font-size: 16px !important;
      font-weight: 600;
      .mixinFlex(space-between; center);
      padding-top: 12px;
      border-top: 1px solid #f4f4f4;
    }
  }
  &.list {
    .cover-wrap {
      width: 258px;
      height: 145px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
.cover-content {
  width: 100%;
  height: 100%;
  .mixinFlex(center; center; column);
  &.cover-blue {
    background-color: #468ce3;
  }
  &.cover-gray {
    background-color: #7a7a7a;
  }
  &.cover-orange {
    background-color: #e8993e;
  }
  .img {
    .mixinImgWrap(48px; 48px);
  }
  .text {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    .mixinEllipsis(26px);
    margin-top: 16px;
  }
}
</style>
